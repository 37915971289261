<template>
    <div class="modal-alert-no-x">
        <div class="content">
            <div class="title" v-html="$translate(title)" />
            <div class="body" v-html="body" />
        </div>
        <div class="button-wrapper">
            <button @click="$emit('close')" class="btn brand-primary f-16" v-html="$translate('CONFIRM')" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalAlertNoX',
    props: ['options'],
    computed: {
        title() {
            return (this.options || {}).title
        },
        body() {
            return (this.options || {}).body
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-alert-no-x {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 !important;
    width: 280px;

    .content {
        padding: 20px 24px;
        text-align: center;

        .title {
            line-height: normal;
            color: black;
            font-family: NotoSans-Medium;
            font-size: 18px;
            letter-spacing: -0.2px;
        }

        .body {
            color: black;
            text-align: center;
            margin-top: 12px;
            font-size: 15px;
            letter-spacing: -0.2px;
            line-height: 20px;
        }
    }

    .button-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid $grey-02;
        height: 50px;

        button {
            font-family: NotoSans-Regular;
        }
    }
}
</style>
